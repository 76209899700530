import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import { saveAs } from 'file-saver';
import { json2csv } from 'json2csv';
import * as XLSX from 'xlsx';
import { parse } from 'json2csv'; 
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'; 
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, CardTitle, Col, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from "reactstrap";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";  // Import FontAwesome icons
import { toastr } from "react-redux-toastr";
import { MoreVertical } from 'react-feather'; 
const SalesOpp = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [localDeviceProject, setLocalDeviceProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [salesOpps, setSalesOpps] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [personArray, setPersonArray] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);  // Modal for viewing details
  const [currentRow, setCurrentRow] = useState(null);
  const [newDescription, setNewDescription] = useState("");
  const [ticketInfo, setTicketInfo] = useState([]);
  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleViewModal = () => setViewModalOpen(!viewModalOpen);  // Toggle view modal
  const getUnixTimestamp = () => Math.floor(Date.now() / 1000);
  const handleEdit = (row) => {
    setCurrentRow(row);
    setNewDescription(row.Description);
    toggleModal();
  };

  const handleView = (row) => {
    setCurrentRow(row);
    toggleViewModal();
  };

  const handleDelete = async (row) => {
    const projectId = row.ProjectId;
    const email = row.company?.Relations?.Relation?.[0]?.EmailAddresses?.Work?.Value;
    const userid = ticketInfo[email]?.userid; 
    const standid = ticketInfo[email]?.standid; 
    const salesOppId = row.Id;
  
    if (window.confirm(`Are you sure you want to delete ID: ${salesOppId}?`)) {
      try {
        const apiUrlRemoveUser = 'https://compassfairs.no/wp-json/fairsas/removeUserFromStand';
        const requestData = {
          userid: userid, 
          stand_id: standid, 
          project_id: projectId 
        };
  
        // Remove user from stand
        const responseUserRemoval = await axios.post(apiUrlRemoveUser, requestData, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
  
        if (responseUserRemoval.data.status === 'success') {
          toastr.success("[OK]", `Successfully deleted from Wordpress and Moosend`);
          
          // Remove sales opportunity
          const apiUrlRemoveSalesOpp = `https://api2.fairs.as/24so/soap/deleteSalesOpp/${salesOppId}`;
          const responseSalesOppRemoval = await axios.get(apiUrlRemoveSalesOpp);
  
          if (responseSalesOppRemoval.data.status === 'successfully deleted') {
            toastr.success("[OK]", `Sales opportunity ID: ${salesOppId} successfully deleted from ERP System.`);
            setSalesOpps(prevSalesOpps =>
              prevSalesOpps.filter(salesOpp => salesOpp.Id !== salesOppId)
            );
          } else {
            toastr.error("[Error]", `Failed to delete sales opportunity ID: ${salesOppId}`);
          }
        } else {
          toastr.error("[Error]", `Failed to delete record ID: ${salesOppId}`);
        }
      } catch (error) {
        console.error("Error deleting record:", error);
        toastr.error("[Error]", `Failed to delete record ID: ${salesOppId}`);
      }
    }
  };
  

  const handleSave = async () => {
    const salesOpportunity = {
      salesOpp: {
        Title: currentRow.Title,
        Id: currentRow.Id,
        Description: newDescription,
        ProjectId: currentRow.ProjectId,
        CustomerId: currentRow.CustomerId,
        DateChanged: new Date().toISOString(),
      }, 
    };

    try {
      const response = await axios.put(`https://api2.fairs.as/24so/soap/updateSalesOpp?id=${currentRow.Id}`, salesOpportunity);
      if (response.data.status === 'successfully updated') {
        toastr.success("[OK]", `updated ${currentRow.Description} to ${newDescription}`);

        setSalesOpps(prevSalesOpps =>
          prevSalesOpps.map(salesOpp =>
            salesOpp.Id === currentRow.Id
              ? { ...salesOpp, Description: newDescription }
              : salesOpp
          )
        );
        toggleModal();
      } else {
        alert("Update failed");
      }
    } catch (error) {
      console.error("Error updating stand number:", error);
      alert("Update failed");
    }
  };

  useEffect(() => {
    const loadOptions = async (inputValue = "") => {
      setIsLoading(true);
      try {
        const apiUrl = "https://api2.fairs.as/24so/soap/projects";
        const response = await axios.get(apiUrl);
  
        const projectOptions = response.data.Project
          .filter(
            (item) =>
              item.TypeId === 2080 && // Only include projects with TypeId === 2080
              (item.Id.toString().includes(inputValue) ||
              item.Name.toLowerCase().includes(inputValue.toLowerCase()))
          )
          .map((item) => ({
            value: item.Id,
            label: item.Name,
            type: item.TypeName
          }));
  
        setProjectOptions(projectOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    loadOptions();
  }, []);

  const handleLocalDeviceProjectChange = async (selectedOption) => {
    setLocalDeviceProject(selectedOption);
    if (selectedOption) {
      setIsLoading2(true);
      try {
        const apiUrl = `https://api2.fairs.as/24so/soap/salesOpp?projectId=${selectedOption.value}`;
        const response = await axios.get(apiUrl);
        setSalesOpps(response.data.enrichedSalesOpps);
  
        const industriesArray = response.data.industries.KeyValuePair;
        const industriesObject = industriesArray.reduce((acc, pair) => {
          acc[pair.Key] = pair.Value;
          return acc;
        }, {});
  
        const personsArray = response?.data?.getPersons || null;
        setPersonArray(personsArray);
        setIndustries(industriesObject);

        const ticketInfoUrl = `https://compassfairs.no/wp-json/fairsas/getTicketInfo?pid=${selectedOption.value}`;
        const ticketResponse = await axios.get(ticketInfoUrl);
  
        // Map email as the key and the rest of the data as value
        const ticketInfoObject = ticketResponse?.data?.response.reduce((acc, person) => {
          acc[person?.Email] = {
            Name: person?.Name,
            ticketUrl: person?.ticketUrl,
            userid: person?.userid,
            standid: person?.standid
          };
          return acc;
        }, {});

        console.log(ticketInfoObject);
        // Store the ticket info in the state
        setTicketInfo(ticketInfoObject);
        setIsLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading2(false);
      }
    } else {
      setSalesOpps([]);
    }
  };
  

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: 'Description',
      text: 'Standnr',
      sort: true, 
      headerStyle: { width: '7%' }
    },
    {
      dataField: 'company.NickName',
      text: 'Utstillernavn',
      sort:true,
      headerStyle: { width: '18%' }
    },
    {
      dataField: 'CustomerContactId',
      text: 'Kontaktperson',
      sort:true,
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
        return `${relevantRelation?.FirstName || 'N/A'} ${relevantRelation?.LastName || 'N/A'}`;
      },
    },
    {
      dataField: 'CustomerContactId',
      text: 'Kontaktperson e-post',
      sort:true,
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
        return relevantRelation?.EmailAddresses?.Work?.Value || 'N/A';
      },
    },

    {
      dataField: 'CustomerContactId',
      text: 'Kontaktperson tlf',
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
        return relevantRelation?.PhoneNumbers?.Mobile?.Value || '';
      },
    },
    {
      dataField: 'ticketUrl',
      text: 'URL',
      sort: true,
      formatter: (cell, row) => {
        const relations = row.company?.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === row.CustomerContactId
        );
      
        if (!relevantRelation || !relevantRelation.EmailAddresses?.Work?.Value) {
          return 'N/A';
        }
      
        const emailKey = relevantRelation.EmailAddresses.Work.Value.toLowerCase();
        const ticketUrl = ticketInfo[emailKey]?.ticketUrl;
      
        if (ticketUrl) {
          const cleanUrl = ticketUrl.replace(/^https?:\/\/(www\.)?/i, '');
          return (
            <a href={`https://${cleanUrl}`} target="_blank" rel="noopener noreferrer">
              {ticketUrl}
            </a>
          );
        }
      
        return 'N/A';
      }
      
    },
    {
      dataField: 'Actions',
      text: 'Actions',
      formatter: (cell, row) => (
        <>
          <FaEye onClick={() => handleView(row)} style={{ cursor: "pointer", marginRight: 10 }} />
          <FaEdit onClick={() => handleEdit(row)} style={{ cursor: "pointer", marginRight: 10 }} />
          <FaTrash onClick={() => handleDelete(row)} style={{ cursor: "pointer" }} />
        </>
      ),
    },
  ];

  const exportToCSV = (salesOpps) => {
    try {
      const csvData = salesOpps.map((opp) => {
        const company = opp.company || {};
        const relations = company.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === opp.CustomerContactId,
        );
  
        return {
          Standnummer: opp.Description || 'N/A',
          Prosjektnr: opp.ProjectId || 'N/A',
          Prosjektnavn: opp.Title || 'N/A',
          KundeID: opp.CustomerId,
          OrgNr: company.OrganizationNumber || 'N/A',
          Firmanavn: company.Name || 'N/A',
          Utstillernavn: company.NickName || 'N/A',
          Bransje: industries[company.IndustryId] || 'N/A',
          Produkt: company.ExternalId,
          Addresse: company?.Addresses?.Visit?.Street || 'N/A',
          Postnummer: company?.Addresses?.Visit?.PostalCode || 'N/A',
          Sted: company?.Addresses?.Visit?.PostalArea || 'N/A',
          Land: company?.Addresses?.Visit?.Counry || 'N/A',
          KontaktPersonFornavn: relevantRelation?.FirstName || 'N/A',
          KontaktPersonEtternavn: relevantRelation?.LastName || 'N/A',
          Mobil: relevantRelation?.PhoneNumbers?.Mobile?.Value || 'N/A',
          Epost: relevantRelation?.EmailAddresses?.Work?.Value || 'N/A',
          BillettLink: ticketInfo[company?.Relations?.Relation?.find(
            (relation) => relation.ContactId === opp.CustomerContactId
        )?.EmailAddresses?.Work?.Value.toLowerCase()]?.ticketUrl || 'System could not generate the ticket',
          SourceId: opp.SourceId ? (opp.SourceId == 11385 ? 'Ikke expobox' : '') : '',
        };
        
      });
      const timestamp = getUnixTimestamp();
      const csv = parse(csvData); // Parse the structured data into CSV
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `Export_${timestamp}.csv`); // Save with unique name
    } catch (error) {
      console.error('Error exporting CSV:', error); // Error handling
    }
  };

  const exportToXLS = (salesOpps) => {
    try {
      const xlsData = salesOpps.map((opp) => {
        const company = opp.company || {};
        const relations = company.Relations?.Relation || [];
        const relevantRelation = relations.find(
          (relation) => relation.ContactId === opp.CustomerContactId,
        );
  
        return {
          Standnummer: opp.Description || 'N/A',
          Prosjektnr: opp.ProjectId || 'N/A',
          Prosjektnavn: opp.Title || 'N/A',
          KundeID: opp.CustomerId,
          OrgNr: company.OrganizationNumber || 'N/A',
          Firmanavn: company.Name || 'N/A',
          Utstillernavn: company.NickName || 'N/A',
          Bransje: industries[company.IndustryId] || 'N/A',
          Produkt: company.ExternalId,
          KontaktPersonFornavn: relevantRelation?.FirstName || 'N/A',
          KontaktPersonEtternavn: relevantRelation?.LastName || 'N/A',
          Mobil: relevantRelation?.PhoneNumbers?.Mobile?.Value || 'N/A',
          Addresse: company?.Addresses?.Visit?.Street || 'N/A',
          Postnummer: company?.Addresses?.Visit?.PostalCode || 'N/A',
          Sted: company?.Addresses?.Visit?.PostalArea || 'N/A',
          Land: company?.Addresses?.Visit?.Counry || 'N/A',
          Epost: relevantRelation?.EmailAddresses?.Work?.Value || 'N/A',
          BillettLink: ticketInfo[company?.Relations?.Relation?.find(
            (relation) => relation.ContactId === opp.CustomerContactId
        )?.EmailAddresses?.Work?.Value.toLowerCase()]?.ticketUrl || 'System could not generate the ticket',
          SourceId: opp.SourceId ? (opp.SourceId == 11385 ? 'Ikke expobox' : '') : '',
        };
      });
  
      const worksheet = XLSX.utils.json_to_sheet(xlsData); // Create Excel worksheet
      const workbook = XLSX.utils.book_new(); // Create a new workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'SalesOpp'); // Append the worksheet
  
      const binaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
      const buffer = new ArrayBuffer(binaryString.length);
      const view = new Uint8Array(buffer);
  
      for (let i = 0; i < binaryString.length; i++) {
        view[i] = binaryString.charCodeAt(i); // Convert binary to Uint8Array
      }const timestamp = getUnixTimestamp();
  
      const blob = new Blob([buffer], { type: 'application/octet-stream' }); // Create a Blob
      saveAs(blob, `Export_${timestamp}.xlsx`); // Save with unique name
    } catch (error) {
      console.error('Error exporting XLS:', error); // Error handling
    }
  };

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Exhibitor list</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/dashboard">Boligmesse</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Link to="#">Exhibitor list</Link>
          </BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h5" className="mb-0 pt-2">
                Select Project
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Select
                options={projectOptions}
                isClearable
                onChange={handleLocalDeviceProjectChange}
                value={localDeviceProject}
                isSearchable
                placeholder="Select or search for projects..."
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isLoading2 ? (
  <p>Loading ticket information...</p>
) : (
  salesOpps.length > 0 && (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <div className="card-actions float-right">
              <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle tag="a">
                  <MoreVertical />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => exportToCSV(salesOpps)}>Export to CSV</DropdownItem>
                  <DropdownItem onClick={() => exportToXLS(salesOpps)}>Export to XLS</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <CardTitle tag="h5" className="mb-0">
              Exhibitor list
              <span> ( {salesOpps.length} ) </span>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <ToolkitProvider
              keyField="Id"
              data={salesOpps}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                    <SearchBar {...props.searchProps} placeholder="Search in table..." />
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      bordered={false}
                      hover
                      striped
                      noDataIndication="No data available"
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
)}


{/* View Modal */}
<Modal isOpen={viewModalOpen} toggle={toggleViewModal}>

<ModalBody style={{ border: '1px solid #ccc' }}> {/* Add border to the entire modal body */}
    {currentRow && (
        <div>
            {/* Header Row */}
            <div className="row" style={{ border: '1px solid #153d77', borderBottom: '1px solid #153d77',  marginBottom: '-1px',  marginRight:'unset !important', marginLeft:'unset !important' }}>
                <div className="col-6" style={{ borderRight: '1px solid #153d77', padding: '10px', boxSizing: 'border-box' }}>
                    <p><strong></strong> {currentRow.company.NickName || 'N/A'}</p>
                </div>
                <div className="col-6" style={{ padding: '10px', textAlign: 'right', boxSizing: 'border-box' }}>
                    <p><strong></strong> {currentRow.Description || 'N/A'}</p>
                </div>
            </div>

            {/* Body Section */}
            <div style={{ padding: '10px 0', border: '1px solid #153d77', borderTop: 'none',
             }}>
                <div style={{ margin: '15px', }}> {/* Container for body text with left margin */}
                    <p><strong>KundeID:</strong> {currentRow.CustomerId || 'N/A'}</p>
                    <p><strong>Org. nr.: </strong>{currentRow.company.OrganizationNumber || 'N/A'}</p>
                    <p><strong>Firmanavn: </strong>{currentRow.company.Name || 'N/A'}</p>
                    <p><strong>Bransje: </strong>{industries[currentRow.company.IndustryId] || 'N/A'}</p>
                    <p><strong>Produkt: </strong>{currentRow.company.ExternalId || 'N/A'}</p>
                    <br/>

                    <p><strong>Kontaktperson: </strong>
                        {currentRow.company?.Relations?.Relation?.find(
                            (relation) => relation.ContactId === currentRow.CustomerContactId
                        ) 
                        ? `${currentRow.company.Relations.Relation.find(
                            (relation) => relation.ContactId === currentRow.CustomerContactId
                        )?.FirstName || 'N/A'} ${currentRow.company.Relations.Relation.find(
                            (relation) => relation.ContactId === currentRow.CustomerContactId
                        )?.LastName || 'N/A'}`
                        : 'N/A'}
                    </p>
                    <p><strong>Kontaktperson e-post: </strong>{currentRow.company?.Relations?.Relation?.find(
                        (relation) => relation.ContactId === currentRow.CustomerContactId
                    )?.EmailAddresses?.Work?.Value || 'N/A'}</p>
                    <p><strong>Kontaktperson mobil: </strong>{currentRow.company?.Relations?.Relation?.find(
                        (relation) => relation.ContactId === currentRow.CustomerContactId
                    )?.PhoneNumbers?.Mobile?.Value || 'N/A'}</p>
                    <p><strong>Våres ref: </strong>{personArray[currentRow.Owner] || 'N/A'}</p>
                    <br/>
                    <p><strong>Prosjekt: </strong>{currentRow.Title} ({currentRow.ProjectId})</p>
                    <hr style={{borderTop:"1px solid rgb(21, 61, 119)"}}/>
                    <p><strong>Billettlink: </strong>{ticketInfo[currentRow.company?.Relations?.Relation?.find(
                        (relation) => relation.ContactId === currentRow.CustomerContactId
                    )?.EmailAddresses?.Work?.Value.toLowerCase()]?.ticketUrl || 'System could not generate the ticket ...'}</p>
                </div>
            </div>
        </div>
    )}
</ModalBody>




    <ModalFooter>
        <Button color="secondary" onClick={toggleViewModal}>Close</Button>
    </ModalFooter>
</Modal>


      {/* Edit Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Stand Number</ModalHeader>
        <ModalBody>
          {currentRow && (
            <div>
              <p><strong>Customer ID:</strong> {currentRow.CustomerId}</p>
              <p><strong>Current Description:</strong></p>
              <Input
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>Save</Button>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default SalesOpp;
